
















import { Component, Vue, Prop } from 'vue-property-decorator'
import SlideDeckResourceList from './SlideDeckResourceList.vue'
import SlideOptions from './SlideOptions.vue'
import { Resource, ResourceType } from '@/store/modules/resource'

@Component({
  components: {
    SlideDeckResourceList,
    SlideOptions
  }
})
export default class SlideDeckResources extends Vue {
  show: boolean = false
  @Prop(String) currentPageKey!: string
  @Prop(Number) currentPageNumber!: number
  @Prop() currentPageResources!: Resource[]
  // @Prop(Number) currentPage!: number

  get resourceCount () {
    return this.currentPageResources ? this.currentPageResources.length : 0
  }

  get resourceTypes () {
    return this.$store.getters.getResourceTypes.sort((a: ResourceType, b: ResourceType) => {
      return (a.name === 'References' || a.name < b.name) ? -1 : a.name > b.name ? 1 : 0
    })
  }

  toggleResources (): void {
    this.show = !this.show
  }

  getPulloutBgColor () {
    if (this.show) {
      return this.$store.getters.getBGStyle3
    } else {
      return this.$store.getters.getBGStyle1
    }
  }
}
