
















































































import PDFFindControls from '@/components/pdfviewer/PDFFindControls.vue'
import PDFPaginator from '@/components/pdfviewer/PDFPaginator.vue'
import PDFReturnToTop from '@/components/pdfviewer/PDFReturnToTop.vue'
import PDFZoom from '@/components/pdfviewer/PDFZoom.vue'
import PDFPrint from '@/components/pdfviewer/PDFPrint.vue'
import PDFViewer from '@/components/pdfviewer/PDFViewer.vue'
import SlideDeckSectionSelect from '@/views/products/summary-slides/@components/SlideDeckSectionSelect.vue'
import SlideDeckResources from '@/views/products/summary-slides/@components/SlideDeckResources.vue'
import SlideDeckDownloader from '@/views/products/summary-slides/@components/SlideDeckDownloader.vue'
import CustomSlideDeckButton from '@/views/products/summary-slides/@components/CustomSlideDeckButton.vue'
import CustomSlideDeckOptions from '@/views/products/summary-slides/@components/CustomSlideDeckOptions.modal.vue'

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SlideDeck, SlideDeckSection, SlideDeckPage } from '@/store/modules/slideDeck'
import { Resource } from '@/store/modules/resource'

import { PDFViewer as pdfjsViewer } from 'pdfjs-dist/lib/web/pdf_viewer'
import { PDFLinkService } from 'pdfjs-dist/lib/web/pdf_link_service'
import { PDFFindController } from 'pdfjs-dist/lib/web/pdf_find_controller'

@Component({
  components: {
    PDFFindControls,
    PDFPaginator,
    PDFReturnToTop,
    PDFZoom,
    PDFPrint,
    PDFViewer,
    SlideDeckSectionSelect,
    SlideDeckDownloader,
    SlideDeckResources,
    CustomSlideDeckOptions,
    CustomSlideDeckButton
  }
})
export default class SlideDeckViewer extends Vue {
  @Prop() slideDeck?: SlideDeck

  url: string = ''
  scale: number = 1
  optimalScale: number = 4
  fit?: any = 'auto'
  width: any = 'auto'
  // minWidth: number = 0
  pageCount?: number = 0
  isPreviewEnabled: boolean = false
  searchActive: boolean = false
  slideDeckSectionOpts: any[] = []
  slideDeckSections: SlideDeckSection[] = []
  currentPageNumber: number = 1
  currentSlideDeckSection?: SlideDeckSection
  currentSlideDeckSectionKey: string = '' // not computed because it's 2-way bound to slideDeckSectionSelect component
  currentPage?: SlideDeckPage
  currentPageKey: string = ''
  currentPageResources: Resource[] = []
  allReferences: Resource[] = []
  pdfLinkService?: any = null
  pdfFindController?: any = null
  docIsLoaded: Boolean = false
  pageToLoadKey: string = ''

  showDisclaimer: boolean = false
  modalMsg: string = process.env.SLIDE_DECK_DISCLAIMER_TEXT
  disclaimerEnabled: boolean = process.env.SLIDE_DECK_DISCLAIMER_ENABLED
  async mounted () {
    // eslint-disable-next-line new-cap
    const _viewerInstance = new pdfjsViewer({
      container: document.getElementById('MainPdfViewer')
    })
    this.pdfLinkService = new PDFLinkService({ eventBus: _viewerInstance.eventBus })

    this.pdfFindController = new PDFFindController({
      linkService: this.pdfLinkService,
      eventBus: _viewerInstance.eventBus
    })
    this.pdfLinkService.setViewer(this)

    await this.initPdf()
  }

  async initPdf () {
    if (!this.slideDeck) {
      return
    }
    // only act if the route/slideDeck has actualy changed
    const _newUrl = `${process.env.VUE_APP_API_HOST || 'http://localhost:3305'}/files/slide-decks/${this.slideDeck.key}.pdf`
    if (this.url === _newUrl) {
      return
    }
    this.url = _newUrl
    this.slideDeckSections = this.slideDeck.slideDeckSections || []
    this.currentPageNumber = 1
    this.currentSlideDeckSection = undefined
    this.currentPage = undefined
    this.currentPageKey = ''
    this.currentPageResources = []
    this.slideDeckSectionOpts = []
    this.currentSlideDeckSectionKey = ''
    if (this.slideDeckSections && this.slideDeckSections.length) {
      this.currentSlideDeckSection = this.slideDeckSections[0]
      this.currentSlideDeckSectionKey = this.currentSlideDeckSection.key // not computed because it's 2-way bound to gvdSectionSelect component
      if (this.currentSlideDeckSection.slideDeckPages && this.currentSlideDeckSection.slideDeckPages.length) {
        this.currentPage = this.currentSlideDeckSection.slideDeckPages[0]
        this.currentPageKey = this.currentPage.key
        this.currentPageResources = this.currentPage.resources
        this.slideDeckSectionOpts = this.slideDeckSections.map((s: SlideDeckSection) => {
          return {
            text: s.title,
            value: s.key
          }
        })
      }
    }

    this.$store.dispatch('loadCustomDeck', this.slideDeck.key)
  }

  floor (value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.floor(value * multiplier) / multiplier
  }

  loadPageByKey (key: string) {
    if (!key || !key.length) {
      return
    }
    let pageNo = 1;

    // if (this.$route.query.page !== undefined) {
    (this.slideDeckSections as SlideDeckSection[]).forEach((section: SlideDeckSection) => {
      const page = section.slideDeckPages.find((page: SlideDeckPage) => {
        return page.key === key
      })
      if (page) {
        pageNo = page.pageNo
      }
    })
    // }
    this.updateCurrentPage(pageNo)
  }

  widthChanged (width: any) {
    this.width = width
    // this.minWidth = this.minWidth || width
  }

  onDocumentRendered () {
    // if we have a page key, load it. Otherwise make sure we're on the first page.
    if (this.pageToLoadKey && this.pageToLoadKey.length) {
      this.loadPageByKey(this.pageToLoadKey)
      this.pageToLoadKey = ''
    } else {
      this.updateCurrentPage(1)
      // (document.getElementById('app') as HTMLElement).scrollTop = 0
    }
    this.docIsLoaded = true
    this.$emit('document-rendered', this.url)
    this.handleDisclaimer()
  }

  hideModal () {
    sessionStorage.setItem('showDisclaimer', 'false')
    this.showDisclaimer = false
  }

  handleDisclaimer () {
    const showModal = sessionStorage.getItem('showDisclaimer')

    if (showModal !== 'false') {
      this.showDisclaimer = true
    }
  }

  onDocumentErrored (e: any) {
    this.$emit('document-errored', e)
  }

  updateScale ({ scale, isOptimal = false }: any) {
    const roundedScale = this.floor(scale, 2)
    if (isOptimal) this.optimalScale = roundedScale
    this.scale = roundedScale
  }

  updateFit (fit: string) {
    this.fit = fit
  }

  updatePageCount (pageCount: number) {
    this.pageCount = pageCount
  }

  updateCurrentPage (pageNumber: number) {
    if (this.currentPageNumber === pageNumber) {
      this.$router.replace({ query: { '': null } }).catch(() => {})
      // return
    }
    this.currentPageNumber = pageNumber
    this.$store.dispatch('setActiveSlideDeckPage', pageNumber).then((slideDeckPage: SlideDeckPage) => {
      this.currentPage = slideDeckPage || undefined
      this.currentPageKey = slideDeckPage ? slideDeckPage.key : ''
      this.currentPageResources = slideDeckPage ? slideDeckPage.resources : []
      if (this.currentPage && this.currentPage.key !== this.$route.query.page) {
        this.$router.replace({ query: { '': null } }).catch(() => {})
      }
      this.$emit('page-focus', this.currentPageNumber)
    })
  }

  togglePreview () {
    this.isPreviewEnabled = !this.isPreviewEnabled
  }

  isPageVisible (pageNo: number) {
    return this.currentPageNumber === pageNo
  }

  findStart () {
    this.searchActive = true
  }

  findEnd () {
    this.searchActive = false
  }

  @Watch('slideDeck')
  slideDeckChanged () {
    this.initPdf()
  }

  @Watch('url')
  urlChanged () {
    this.currentPageNumber = 1
  }

  @Watch('currentPageNumber')
  currentPageNumberChanged () {
    const _newActiveSection = (this.slideDeckSections as SlideDeckSection[]).find((s: any) => s.slideDeckPages.some((p: any) => p.pageNo === this.currentPageNumber))
    this.currentSlideDeckSectionKey = _newActiveSection ? _newActiveSection.key : ''
  }

  @Watch('currentSlideDeckSectionKey')
  currentSlideDeckSectionKeyChanged () {
    const _newActiveSection = (this.slideDeckSections as SlideDeckSection[]).find((s: any) => s.key === this.currentSlideDeckSectionKey)
    if (_newActiveSection && _newActiveSection.slideDeckPages.length) {
      this.$store.dispatch('setActiveSlideDeckSection', _newActiveSection)
      const _pageInSection = _newActiveSection.slideDeckPages.find((p: any) => {
        return p.pageNo === this.currentPageNumber
      })
      this.updateCurrentPage(_pageInSection ? _pageInSection.pageNo : _newActiveSection.slideDeckPages[0].pageNo)
    }
  }

  @Watch('$route.query.page', { immediate: true, deep: true })
  routeQueryPageChanged () {
    if (this.$route.query.page) {
      this.pageToLoadKey = this.$route.query.page as string
      this.loadPageByKey(this.pageToLoadKey as string)
    }
  }
}
