














import { Component, Vue } from 'vue-property-decorator'
import SlideDeckViewer from './@components/SlideDeckViewer.vue'
import ProductHeader from '@/components/content/ProductHeader.vue'
import store from '@/store/store'
import { SlideDeck, SlideDeckUpdate } from '@/store/modules/slideDeck'
import { Product } from '@/store/modules/product'
import ChangeLogModal from '../ChangeLog.modal.vue'
import { GvdUpdate } from '@/store/modules/gvd'

@Component({
  components: {
    SlideDeckViewer,
    ProductHeader,
    ChangeLogModal
  }
})
export default class SummarySlidesDashboard extends Vue {
  slideDeck: SlideDeck = this.$store.getters.getActiveSlideDeck
  documentError?: string = undefined
  activeChangeLog: GvdUpdate[] | SlideDeckUpdate[] = []

  mounted () {
    this.$store.dispatch('loadResourceTypes')
    this.slideDeck = this.$store.getters.getActiveSlideDeck
  }

  onDocumentErrored (e: any) {
    this.documentError = e.text
  }

  openSlideDeckChangeLogModal (key: string) {
    this.$store.dispatch('loadSlideDeckChangeLog', key).then((updates: SlideDeckUpdate[]) => {
      this.activeChangeLog = updates
      this.$bvModal.show('change-log-modal')
    })
  }

  async beforeRouteUpdate (to: any, from: any, next: Function) {
    if (store.getters.getActiveSlideDeck.key === to.params.slideDeckKey) {
      return next()
    }
    const _product: Product = await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.slideDeckKey) {
      if (!_product.slideDecks || !_product.slideDecks.length) {
        return next(`/product/${_product.key}`)
      }
      return next(`/product/${_product.key}/summary-slides/${_product.slideDecks[0].key}`)
    }
    const _slideDeck: SlideDeck = await store.dispatch('loadSlideDeck', to.params.slideDeckKey)
    if (_slideDeck) {
      await store.dispatch('loadSlideDeckSections', _slideDeck.slideDeckSections)
      await store.dispatch('loadSlideDeckPages', _slideDeck.slideDeckSections)
      await store.dispatch('loadProductResourcesForMarket', to.params.productKey)
      this.slideDeck = store.getters.getActiveSlideDeck
      return next()
    }
    return next(`/product/${_product.key}`)
  }

  async beforeRouteEnter (to: any, from: any, next: Function) {
    const _product: Product = await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.slideDeckKey) {
      if (!_product.slideDecks || !_product.slideDecks.length) {
        return next(`/product/${_product.key}`)
      }
      return next(`/product/${_product.key}/summary-slides/${_product.slideDecks[0].key}`)
    }
    const _slideDeck: SlideDeck = await store.dispatch('loadSlideDeck', to.params.slideDeckKey)
    if (_slideDeck) {
      await store.dispatch('loadSlideDeckSections', _slideDeck.slideDeckSections)
      await store.dispatch('loadSlideDeckPages', _slideDeck.slideDeckSections)
      await store.dispatch('loadProductResourcesForMarket', to.params.productKey)
      return next()
    }
    return next(`/product/${_product.key}`)
  }
}
